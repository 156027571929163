// extracted by mini-css-extract-plugin
export var buttonCreateContainer = "ProviderBrochureNewForm__buttonCreateContainer__WotMI";
export var column = "ProviderBrochureNewForm__column__swNf7";
export var documentRow = "ProviderBrochureNewForm__documentRow__ZJGXH";
export var editor = "ProviderBrochureNewForm__editor__jGeBD";
export var fileNameText = "ProviderBrochureNewForm__fileNameText__QGUDg";
export var fileSizeText = "ProviderBrochureNewForm__fileSizeText__aN3P2";
export var flexContainer = "ProviderBrochureNewForm__flexContainer__p3n1h";
export var grid = "ProviderBrochureNewForm__grid__IqkAw";
export var iconDelete = "ProviderBrochureNewForm__iconDelete__pd_t7";
export var iconView = "ProviderBrochureNewForm__iconView__j98vb";
export var labelContainer = "ProviderBrochureNewForm__labelContainer__lyAhT";
export var publishedAtPicker = "ProviderBrochureNewForm__publishedAtPicker__Y1sjQ";
export var row = "ProviderBrochureNewForm__row__sj2_U";
export var uploadDocumentButton = "ProviderBrochureNewForm__uploadDocumentButton__t5IwJ";